import React, { useState } from "react";
import Close from "@assets/Buy/Close.svg";
import Bag from "@assets/Table/MiniBag.svg";
import IconChoose from "@assets/Settings_tuor/IconChoose.png";
import Champion from "@assets/Settings_tuor/Champion.png";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { IBooster, IUser } from "@utils/types";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import api from "@services/client/api";
import PopupImgCover from "./ChooseImgCover";
import PopupImgProfile from "./ChooseImgProfile";
import Cover from "@assets/Background/edit-profile.png";
import Discord from "@assets/Discord.svg";
import DiscordProvider from "@components/Providers/discord";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";

type Props = {
  user: IUser;
  updateUser: (user: IUser) => void;
  token: string;
  boosterInfo: IBooster | null;
};

type FormUpdateUserData = {
  name: string;
  phone: string;
  discord: string | null;
};

type FormUpdateBoosterData = {
  address: string;
  city: string;
  state: string;
  cep: string;
  telephone: string;
  discord: string;
  payment_key: string;
};

type FormUpdateEmail = {
  email: string;
  emailConfirm: string;
};

type FormUpdateSenha = {
  senha: string;
  novaSenha: string;
  confirmNovaSenha: string;
};

type FormUpdateAddress = {
  estado: string | null;
  cidade: string | null;
  rua: string | null;
  cep: string | null;
};

const userDataSchema = z.object({
  name: z
    .string()
    .min(2, "O seu nome deve ter no mínimo 2 caracteres alfanuméricos.")
    .max(
      64,
      "O nome de usuário deve ter no máximo 64 caracteres alfanuméricos."
    ),
  discord: z.string().optional(),
  phone: z.string(),
});

const boosterSchema = z.object({
  payment_key: z.string().min(1, "A chave de pagamento é obrigatória."),
});

const emailSchema = z
  .object({
    email: z
      .string()
      .min(1, "Digite seu endereço de email.")
      .email("O endereço de email inserido é inválido.")
      .toLowerCase(),
    emailConfirm: z
      .string()
      .min(1, "Digite seu endereço de email.")
      .email("O endereço de email inserido é inválido.")
      .toLowerCase(),
  })
  .refine((fields) => fields.email === fields.emailConfirm, {
    path: ["emailConfirm"],
    message: "Os endereços de e-mail são diferentes.",
  });

const passwordSchema = z
  .object({
    senha: z.string(),
    novaSenha: z.string().min(8, "A senha deve ter no mínimo 8 caracteres."),
    confirmNovaSenha: z
      .string()
      .min(8, "A senha deve ter no mínimo 8 caracteres."),
  })
  .refine((fields) => fields.novaSenha === fields.confirmNovaSenha, {
    path: ["confirmNovaSenha"],
    message: "As senhas não coincidem.",
  });

const addressSchema = z.object({
  estado: z.string().optional(),
  cidade: z.string().optional(),
  rua: z.string().optional(),
  cep: z.string(),
});

const maskCep = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{5})(\d)/, "$1-$2");
  return value.substring(0, 9);
};

const maskPhone = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/^(\d\d)(\d)/g, "($1) $2");
  value = value.replace(/(\d{5})(\d)/, "$1-$2");
  return value.substring(0, 15);
};

const EditProfile = ({ user, updateUser, token, boosterInfo }: Props) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleButtonClick = () => {
    setShowPopup((prevState) => !prevState);
  };

  const [showPopupCover, setShowPopupCover] = useState(false);

  const handleButtonClickCover = () => {
    setShowPopupCover((prevState) => !prevState);
  };

  let userLabel = "";

  if (user.type_permission === "ADMIN") {
    userLabel = "Admin";
  } else if (user.type_permission === "CLIENTE") {
    userLabel = "Cliente";
  } else if (user.type_permission === "BOOSTER") {
    userLabel = "Booster";
  }

  const {
    register: registerUserData,
    handleSubmit: handleSubmitUserData,
    formState: { errors: errorsUserData },
  } = useForm<FormUpdateUserData>({
    mode: "all",
    criteriaMode: "all",
    resolver: zodResolver(userDataSchema),
    defaultValues: {
      name: user.name,
      discord: user.discord,
      phone: user.telephone,
    },
  });

  const {
    register: registerEmail,
    handleSubmit: handleSubmitEmail,
    formState: { errors: errorsEmail },
  } = useForm<FormUpdateEmail>({
    mode: "all",
    criteriaMode: "all",
    resolver: zodResolver(emailSchema),
    defaultValues: {
      email: user.email,
      emailConfirm: "",
    },
  });

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: { errors: errorsPassword },
  } = useForm<FormUpdateSenha>({
    mode: "all",
    criteriaMode: "all",
    resolver: zodResolver(passwordSchema),
    defaultValues: {
      senha: "",
      novaSenha: "",
      confirmNovaSenha: "",
    },
  });

  const {
    register: registerAddress,
    handleSubmit: handleSubmitAddress,
    formState: { errors: errorsAddress },
  } = useForm<FormUpdateAddress>({
    mode: "all",
    criteriaMode: "all",
    resolver: zodResolver(addressSchema),
    defaultValues: {
      estado: user.state,
      cidade: user.city,
      rua: user.address,
      cep: user.cep,
    },
  });

  const {
    register: registerBooster,
    handleSubmit: handleSubmitBooster,
    formState: { errors: errorsBooster },
  } = useForm<FormUpdateBoosterData>({
    mode: "all",
    criteriaMode: "all",
    resolver: zodResolver(boosterSchema),
    defaultValues: {
      payment_key: boosterInfo?.payment_key,
    },
  });

  const onSubmitBooster = async (data: FormUpdateBoosterData) => {
    try {
      const response = await api.patch(
        `/boosters/update-key/${boosterInfo?.id}`,
        {
          payment_key: data.payment_key,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // const updatedUser: IUser = response.data;
      // updateUser(updatedUser);

      toast.info("A chave de pagamento do booster foi atualizada!");
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.error(`${err?.response?.data?.message}`);
        return;
      }

      toast.error(
        "Não foi possível atualizar a chave de pagamento do booster. Tente novamente mais tarde."
      );
    }
  };

  const onSubmitUserData = async (data: FormUpdateUserData) => {
    try {
      const response = await api.patch(
        `/users/update-userdata`,
        {
          name: data.name,
          telephone: data.phone,
          discord: data.discord,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const user: IUser = response.data;
      updateUser(user);

      toast.info(`Os dados do usuário foram atualizados!`);
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.error(`${err?.response?.data?.message}`);
        return;
      }

      toast.error(
        `Não foi possivel atualizar os dados do usuário. Tente novamente mais tarde.`
      );
    }
  };

  const onSubmitAddress = async (data: FormUpdateAddress) => {
    try {
      const response = await api.patch(
        `/users/update-address`,
        {
          estado: data.estado,
          cidade: data.cidade,
          rua: data.rua,
          cep: data.cep,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const user: IUser = response.data;
      updateUser(user);

      toast.info(`O endereço do usuário foi atualizado!`);
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.error(`${err?.response?.data?.message}`);
        return;
      }

      toast.error(
        `Não foi possivel alterar o endereço. Tente novamente mais tarde.`
      );
    }
  };

  const onSubmitEmail = async (data: FormUpdateEmail) => {
    try {
      const response = await api.patch(
        `/users/update-email`,
        {
          email: data.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const user: IUser = response.data;
      updateUser(user);

      toast.info(`O endereço de email foi atualizado!`);
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.error(`${err?.response?.data?.message}`);
        return;
      }

      toast.error(
        `Não foi possivel alterar o endereço de e-mail. Tente novamente mais tarde.`
      );
    }
  };

  const onSubmitPassword = async (data: FormUpdateSenha) => {
    try {
      await api.patch(
        `/users/update-password`,
        {
          senha: data.senha,
          novaSenha: data.novaSenha,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.info(`A senha foi atualizada!`);
    } catch (err) {
      if (err instanceof AxiosError) {
        toast.error(`${err?.response?.data?.message}`);
        return;
      }

      toast.error(
        `Não foi possivel alterar a senha. Tente novamente mais tarde.`
      );
    }
  };

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);

      Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      const formData = new FormData();
      formData.append("file", file);

      try {
        const data = new FormData();
        data.append("file", file);

        await api.patch(`/users/avatar/`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error: any) {
        if (error instanceof AxiosError) {
          toast.error(`Error: ${error?.response?.data?.message}`);
        }

        toast.error(
          `O arquivo de imagem não pode ser enviado. Tente novamente.`
        );
        console.log(error);
      }
    }
  };

  const getFullAvatarUrl = (avatar: string | null) => {
    if (
      avatar &&
      (avatar.startsWith("http://") || avatar.startsWith("https://"))
    ) {
      return avatar;
    }
    return avatar
      ? `https://app-elomania-v2-uploads.s3.amazonaws.com/${avatar}`
      : "";
  };

  const [baseName, hashtagPart] = user.name.split("#");

  return (
    <div>
      {/* <div className="bg-blue-50 rounded-2xl w-1/2 h-96 relative">
        <div className="w-full h-full rounded-2xl">
          <img
            src={
              user.banner
                ? `https://app-elomania-v2-uploads.s3.amazonaws.com/${user.banner}`
                : "https://app-elomania-v2-uploads.s3.amazonaws.com/178ceec000a7fa5d984e-00350c88-83f9-4396-bd00-63298c38f840-banner.jpg"
            }
            alt="Capa"
            className="bg-top bg-cover object-none w-full h-full rounded-2xl"
          />
        </div>
        <div className="absolute top-4 items-center justify-center w-full">
          <div className="ml-4 flex items-center gap-4">
            {userLabel && (
              <div className="bg-gray-500/20 rounded-3xl w-14 py-2 text-center mb-4">
                <p className="text-gray-500 font-normal text-xs">{userLabel}</p>
              </div>
            )}

            <button
              className="bg-gray-500/20 rounded-3xl w-14 py-2 text-center mb-4 flex justify-center items-center cursor-pointer"
              onClick={handleButtonClickCover}
            >
              <img src={IconChoose} alt="IconChoose" />
            </button>
          </div>
          <div className="w-full grid place-items-center gap-2 mt-14">
            <div className="perfil bg-secondary-500 w-24 h-24 rounded-2xl">
              {selectedImage ? (
                <img
                  src={getFullAvatarUrl(user.avatar)}
                  alt="Perfil"
                  className="w-full h-full rounded-2xl"
                />
              ) : (
                <img
                  src={getFullAvatarUrl(user.avatar)}
                  alt="Perfil"
                  className="w-full h-full rounded-2xl"
                />
              )}
            </div>
            {user.type_permission !== "BOOSTER" && (
              <button
                className="absolute mt-12 bg-blue-50 border border-secondary-500 rounded-xl p-1 w-24 flex justify-center items-center cursor-pointer"
                onClick={handleButtonClick}
              >
                <img src={IconChoose} alt="IconChoose" />
              </button>
            )}

            <h3 className="text-base font-normal">
              {baseName}
              {hashtagPart && (
                <span className="text-gray-600">#{hashtagPart}</span>
              )}
            </h3>
          </div>
        </div>
      </div> */}
      {/* Informações */}
      <Card className="w-full bg-transparent p-0">
        <CardBody className="p-0">
          {/* Capa e Perfil */}
          <div className="w-full h-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
            <div className="bg-blue-50 rounded-2xl w-full h-96 relative">
              <div className="w-full h-full rounded-2xl">
                <img
                  src={
                    user.banner
                      ? `https://app-elomania-v2-uploads.s3.amazonaws.com/${user.banner}`
                      : "https://app-elomania-v2-uploads.s3.amazonaws.com/178ceec000a7fa5d984e-00350c88-83f9-4396-bd00-63298c38f840-banner.jpg"
                  }
                  alt="Capa"
                  className="object-cover w-full h-full rounded-3xl"
                />
              </div>
              <div className="absolute top-4 items-center justify-center w-full">
                <div className="ml-4 flex items-center gap-4">
                  {userLabel && (
                    <div className="bg-gray-500/20 rounded-3xl w-14 py-2 text-center mb-4">
                      <p className="text-gray-500 font-normal text-xs">
                        {userLabel}
                      </p>
                    </div>
                  )}

                  <button
                    className="bg-gray-500/20 rounded-3xl w-14 py-2 text-center mb-4 flex justify-center items-center cursor-pointer"
                    onClick={handleButtonClickCover}
                  >
                    <img src={IconChoose} alt="IconChoose" />
                  </button>
                </div>
                <div className="w-full grid place-items-center gap-2 mt-14">
                  <div className="perfil bg-secondary-500 w-24 h-24 rounded-2xl">
                    {selectedImage ? (
                      <img
                        src={getFullAvatarUrl(user.avatar)}
                        alt="Perfil"
                        className="w-full h-full rounded-2xl"
                      />
                    ) : (
                      <img
                        src={getFullAvatarUrl(user.avatar)}
                        alt="Perfil"
                        className="w-full h-full rounded-2xl"
                      />
                    )}
                  </div>
                  {user.type_permission !== "BOOSTER" && (
                    <button
                      className="absolute mt-12 bg-blue-50 border border-secondary-500 rounded-xl p-1 w-24 flex justify-center items-center cursor-pointer"
                      onClick={handleButtonClick}
                    >
                      <img src={IconChoose} alt="IconChoose" />
                    </button>
                  )}

                  <h3 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-lg">
                    {baseName}
                    {hashtagPart && (
                      <span>#{hashtagPart}</span>
                    )}
                  </h3>
                </div>
              </div>
            </div>
            <form
              className="p-4 col-span-2 w-full text-gray-500 transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] border border-gray-100 dark:border-secondary-500 rounded-lg"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmitUserData(onSubmitUserData)();
                handleSubmitPassword(onSubmitPassword)();
              }}
            >
              {user.type_permission !== "BOOSTER" && (
                <div className="flex justify-between items-end">
                  <div className="w-[49%]">
                    <h2 className="mb-4 font-semibold text-lg">
                      {" "}
                      Dados do usuário:{" "}
                    </h2>
                    <input
                      {...registerUserData("name")}
                      type="text"
                      id="name"
                      placeholder="Nome de usuário"
                      className="bg-blue-20 border border-secondary-500 hover:border-blue-1001 rounded-md px-4 py-4 w-full outline-none text-base font-medium text-gray-100"
                    />
                    {errorsUserData.name && (
                      <p className="mt-1 text-sm text-red-800">
                        {errorsUserData.name.message}
                      </p>
                    )}
                  </div>
                  <div className="w-[49%]">
                    <input
                      {...registerEmail("email")}
                      type="text"
                      id="mail"
                      placeholder="Endereço de e-mail: example@domain.com"
                      className="bg-blue-20 border border-secondary-500 hover:border-blue-1001 rounded-md px-4 py-4 w-full outline-none text-base font-medium text-gray-100"
                    />
                    {errorsEmail.email && (
                      <p className="mt-1 text-sm text-red-800">
                        {errorsEmail.email.message}
                      </p>
                    )}
                  </div>
                </div>
              )}
              <div className="mt-6 flex justify-between items-center">
                <div className="w-[49%]">
                  <input
                    {...registerUserData("phone")}
                    type="text"
                    id="phone"
                    placeholder="Telefone"
                    onChange={(event) =>
                      (event.target.value = maskPhone(event.target.value))
                    }
                    className="bg-blue-20 border border-secondary-500 hover:border-blue-1001 rounded-md px-4 py-4 w-full outline-none text-base font-medium text-gray-100"
                  />
                </div>
                <div className="w-[49%]">
                  <input
                    {...registerUserData("discord")}
                    type="text"
                    id="discord"
                    placeholder="Discord"
                    className="bg-blue-20 border border-secondary-500 hover:border-blue-1001 rounded-md px-4 py-4 w-full outline-none text-base font-medium text-gray-100"
                  />
                </div>
              </div>

              <div className="mt-6 flex justify-between items-center">
              <div className="w-[32%]">
                <input
                  {...registerPassword("senha")}
                  type="password"
                  id="password"
                  placeholder="Senha Atual:"
                  className="bg-blue-20 border border-secondary-500 hover:border-blue-1001 rounded-md px-4 py-4 w-full outline-none text-base font-medium text-gray-100"
                />
                {errorsPassword.senha && (
                  <p className="mt-1 text-sm text-red-800">
                    {errorsPassword.senha.message}
                  </p>
                )}
              </div>
              <div className="w-[32%]">
                <input
                  {...registerPassword("novaSenha")}
                  type="password"
                  id="newPassword"
                  placeholder="Nova Senha:"
                  className="bg-blue-20 border border-secondary-500 hover:border-blue-1001 rounded-md px-4 py-4 w-full outline-none text-base font-medium text-gray-100"
                />
              </div>
              <div className="w-[32%]">
                <input
                  {...registerPassword("confirmNovaSenha")}
                  type="password"
                  id="confirmNewPassword"
                  placeholder="Confirme a Senha: "
                  className="bg-blue-20 border border-secondary-500 hover:border-blue-1001 rounded-md px-4 py-4 w-full outline-none text-base font-medium text-gray-100"
                />
              </div>
            </div>
            {errorsPassword.novaSenha && (
              <p className="mt-1 text-sm text-red-800">
                {errorsPassword.novaSenha.message}
              </p>
            )}
            {errorsPassword.confirmNovaSenha && (
              <p className="mt-1 text-sm text-red-800">
                {errorsPassword.confirmNovaSenha.message}
              </p>
            )}



              

              {errorsUserData.discord && (
                <p className="mt-1 text-sm text-red-800">
                  {errorsUserData.discord.message}
                </p>
              )}
              {errorsUserData.phone && (
                <p className="mt-1 text-sm text-red-800">
                  {errorsUserData.phone.message}
                </p>
              )}
              <div className="mt-6">
                <Button
                  className="flex items-center gap-3 bg-primary-500 py-4"
                  size="md"
                  type="submit"
                >
                  Salvar
                </Button>
              </div>
            </form>
          </div>

          {user.type_permission === "BOOSTER" && (
            <form
              className="p-4 w-full text-gray-500 transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] border border-gray-100 dark:border-secondary-500 rounded-lg"
              onSubmit={handleSubmitBooster(onSubmitBooster)}
            >
              <h2 className="mb-4 font-semibold text-lg"> Dados da Chave Pix: </h2>
              <div className="mt-6 w-[100%]">
                <input
                  {...registerBooster("payment_key")}
                  type="text"
                  id="payment_key"
                  placeholder="PIX"
                  defaultValue={boosterInfo?.payment_key || ""}
                  className="bg-blue-20 border border-secondary-500 hover:border-blue-1001 rounded-md px-4 py-4 w-full outline-none text-base font-medium text-gray-100"
                />

                {errorsEmail.email && (
                  <p className="mt-1 text-sm text-red-800">
                    {errorsEmail.email.message}
                  </p>
                )}
              </div>

              <div className="mt-6">
              <Button
                className="flex items-center gap-3 bg-primary-500 py-4"
                size="md"
                type="submit"
              >
                Salvar
              </Button>
              </div>
            </form>
          )}

          <form
            className="mt-6 p-4 w-full text-gray-500 transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] border border-gray-100 dark:border-secondary-500 rounded-lg"
            onSubmit={handleSubmitAddress(onSubmitAddress)}
          >
            <h2 className="mb-4 font-semibold text-lg">
              {" "}
              Endereço do usuário:{" "}
            </h2>
            <div className="flex justify-between items-center">
              <div className="w-[49%]">
                <input
                  {...registerAddress("estado")}
                  type="text"
                  id="state"
                  placeholder="Estado: Rio de Janeiro"
                  className="bg-blue-20 border border-secondary-500 hover:border-blue-1001 rounded-md px-4 py-4 w-full outline-none text-base font-medium text-gray-100"
                />
              </div>
              <div className="w-[49%]">
                <input
                  {...registerAddress("cidade")}
                  type="text"
                  id="city"
                  placeholder="Cidade: Angra dos Reis"
                  className="bg-blue-20 border border-secondary-500 hover:border-blue-1001 rounded-md px-4 py-4 w-full outline-none text-base font-medium text-gray-100"
                />
              </div>
            </div>
            {errorsAddress.estado && (
              <p className="mt-1 text-sm text-red-800">
                {errorsAddress.estado.message}
              </p>
            )}
            {errorsAddress.cidade && (
              <p className="mt-1 text-sm text-red-800">
                {errorsAddress.cidade.message}
              </p>
            )}
            <div className="mt-6 flex justify-between items-center">
              <div className="w-[49%]">
                <input
                  {...registerAddress("cep")}
                  type="text"
                  id="cep"
                  placeholder="CEP: 00000-000"
                  onChange={(event) =>
                    (event.target.value = maskCep(event.target.value))
                  }
                  className="bg-blue-20 border border-secondary-500 hover:border-blue-1001 rounded-md px-4 py-4 w-full outline-none text-base font-medium text-gray-100"
                />
              </div>
              <div className="w-[49%]">
                <input
                  {...registerAddress("rua")}
                  type="text"
                  id="address"
                  placeholder="Rua: Alameda Andorinhas"
                  className="bg-blue-20 border border-secondary-500 hover:border-blue-1001 rounded-md px-4 py-4 w-full outline-none text-base font-medium text-gray-100"
                />
              </div>
            </div>
            {errorsAddress.cep && (
              <p className="mt-1 text-sm text-red-800">
                {errorsAddress.cep.message}
              </p>
            )}
            {errorsAddress.rua && (
              <p className="mt-1 text-sm text-red-800">
                {errorsAddress.rua.message}
              </p>
            )}
            <div className="mt-6">
              <Button
                className="flex items-center gap-3 bg-primary-500 py-4"
                size="md"
                type="submit"
              >
                Salvar
              </Button>
            </div>
          </form>

          {/* <form
            className="mt-6 p-4 w-full text-gray-500 transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5]  dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] border border-gray-100 dark:border-secondary-500 rounded-lg"
            onSubmit={handleSubmitPassword(onSubmitPassword)}
          >
            <h2 className="mb-4 font-semibold text-lg"> Mudar a senha: </h2>
            <div className="mt-6 flex justify-between items-center">
              <div className="w-[32%]">
                <input
                  {...registerPassword("senha")}
                  type="password"
                  id="password"
                  placeholder="Senha Atual:"
                  className="bg-blue-20 border border-secondary-500 hover:border-blue-1001 rounded-md px-4 py-4 w-full outline-none text-base font-medium text-gray-100"
                />
                {errorsPassword.senha && (
                  <p className="mt-1 text-sm text-red-800">
                    {errorsPassword.senha.message}
                  </p>
                )}
              </div>
              <div className="w-[32%]">
                <input
                  {...registerPassword("novaSenha")}
                  type="password"
                  id="newPassword"
                  placeholder="Nova Senha:"
                  className="bg-blue-20 border border-secondary-500 hover:border-blue-1001 rounded-md px-4 py-4 w-full outline-none text-base font-medium text-gray-100"
                />
              </div>
              <div className="w-[32%]">
                <input
                  {...registerPassword("confirmNovaSenha")}
                  type="password"
                  id="confirmNewPassword"
                  placeholder="Confirme a Senha: "
                  className="bg-blue-20 border border-secondary-500 hover:border-blue-1001 rounded-md px-4 py-4 w-full outline-none text-base font-medium text-gray-100"
                />
              </div>
            </div>
            {errorsPassword.novaSenha && (
              <p className="mt-1 text-sm text-red-800">
                {errorsPassword.novaSenha.message}
              </p>
            )}
            {errorsPassword.confirmNovaSenha && (
              <p className="mt-1 text-sm text-red-800">
                {errorsPassword.confirmNovaSenha.message}
              </p>
            )}
            <div className="mt-6">
              <Button
                className="flex items-center gap-3 bg-primary-500 py-4"
                size="md"
                type="submit"
              >
                Salvar
              </Button>
            </div>
          </form> */}

          {/* <div className='w-full flex flex-col gap-1 items-center justify-center'>
          <DiscordProvider>
          <button className="mt-6 w-full text-center py-3 px-4 my-3 bg-blue-50 flex space-x-2 items-center justify-center rounded-lg hover:shadow transition duration-150">
            <img src={Discord} alt="discord" className='w-6 h-6' />
            <span>Víncule sua conta ao Discord</span>
          </button>
          </DiscordProvider>
          <span className='text-gray-100'>Conectado como {user.discord}</span>
          </div> */}
        </CardBody>
      </Card>
      {showPopup && <PopupImgProfile />}
      {showPopupCover && <PopupImgCover />}
    </div>
  );
};

export default EditProfile;
